/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React , { useEffect , useState} from "react";
import { Progress , Modal , Tooltip , message , Collapse} from 'antd';
import { SyncOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import ConnectWallet from "../../component/Connect-wallet/ConnectWallet";
import { useActiveWeb3React } from "../../hooks";
import { useHookGameBet } from "./StoreBet";
import { useContract } from "../../hooks/useContract";
import abiShoot from "../../abi/abiShoot.json";
import abiMintNFT from "../../abi/abiMintNFT.json"
import abiNFTWC from "../../abi/abiNFTWC.json"
import { ADDRESS_SHOOT } from "../../constants";
import { _approveBUSD ,  _buyMysteryBox  } from "./utils";

const ChampionShoot = () => {
    const { account } = useActiveWeb3React();
    const [state, actions]: any = useHookGameBet();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Panel } = Collapse;
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    
    const handleOk = () => {
        setIsModalOpen(false);
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        actions.getAllTeam();
        actions.getReport();
    }, []);

    useEffect(() => {
        if (account) {
            actions.getHistoryMint({
                ownerAddress: account,
                lastTime: 0,
            });
        }
    }, [account]);

    const contentRules = (
        <>
            <div className="top-content-rules">
                <span className="fw-bold">Note</span>: Using bots to mint large quantities of NFTs is considered cheating and unfair to other participants. To protect the interests of other participants, 1Shoot will rescind prizes earned by cheaters.
            </div>
            <div className="bottom-content-rules">
                Football Cup Prediction is a prize game that runs in parallel with the 2022 Qatar World Cup. You can mint for any of the 32 national football teams that qualify for this global football tournament with 1000 1SH token.Mint your global football tournament favorite for a chance to share a prize pool! <br />
                We will add  900 1sh (90%) to the pool prize with each NFT minted. 100 1sh (10%) for each NFT minted will be used for burning after the Final Match.<br />
                Winner takes it all. Pool prize will be claimed after the Fifa World Cup 2022 final match. It will be distributed among all Champion team’s NFT holders. All the Champion NFT will be burnt after claim reward process.
            </div>
        </>
    )

    const startTimeNow = new Date(state.listReport?.closeTime * 1000);

    const startUtc = startTimeNow;

    const convertDateTime = (startUtc: any) => {
        var options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
            timeZoneName: "short",
            hour12: true,
        };
        return startUtc.toLocaleTimeString("en-GB", options);
    };

    const rendererCountDown = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
        }: any) => {
        if (days > 1) {
            return (
                <div>
                    <span>{days}</span> d <span>{hours}</span> h <span>{minutes}</span> m <span>{seconds}</span> s
                </div>
            );
        } else if (days === 1) {
            return (
                <div>
                    <span>{days}</span> d <span>{hours}</span> h <span>{minutes}</span> m <span>{seconds}</span> s
                </div>
            );
        } else {
            return (
                <div>
                    <span>{hours}</span> h <span>{minutes}</span> m <span>{seconds}</span> s
                </div>
            );
        }
    };

    const handleClaimScroll = () => {
        window.scrollTo({ top: 800, behavior: "smooth" });
    };

    const ItemMint = ({item}:any) => {
        const shootContract = useContract(ADDRESS_SHOOT, abiShoot);
        const mintContract = state.listReport?.mintNftContract
        const nftContract = state.listReport?.nftContract
        const mintNftContract:any = useContract(mintContract , abiMintNFT)
        const [isApprove, setApprove] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (shootContract && account !== undefined) {
                shootContract.allowance(account, mintContract).then((res: any) => {
                if (res.toString() / 1e18 >= state.listReport.price) {
                    setApprove(true);
                } else {
                    setApprove(false);
                }
              });
            }
        }, [account]);

        const processPercent = item.totalMint * 100 / state.listReport.totalMint

        const championPrice = state.listReport.prizePool / item.totalMint
        
        const contentTooltips = (
            <>
                {item.totalMint} NFT
            </>
        )

        // _______________handle approce

        const _approveMint = async () => {
            setIsLoading(true);
            try {
              await _approveBUSD(shootContract, mintContract, state.listReport.price).then(
                (res: any) => {
                  res
                    .wait()
                    .then((res1: any) => {
                      if (res1 !== null) {
                        setIsLoading(false);
                        setApprove(true);
                        message.success({
                          type: "success",
                          content: "Approve successfully!",
                          className: "custom-class",
                          duration: 2,
                        });
                        window.location.reload();
                      } else {
                        setIsLoading(false);
                      }
                    })
                    .catch((error: any) => {
                      setIsLoading(false);
                    });
                }
              );
            } catch (error) {
              setIsLoading(false);
            }
        };

        // _________________handle mint NFT

        const web3 = new Web3(
            new Web3.providers.HttpProvider("https://bsc-dataseed1.binance.org/")
        );

        const _handleMint = async () => {
            setIsLoading(true);
            try {
              await _buyMysteryBox(mintNftContract , state.listReport.campaignId , item.tokenId)
                .then(async (res: any) => {
                  if (res.hash) {
                    await actions
                      .getMintNft({
                        teamId: item.teamId,
                        ownerAddress: account,
                        txnHash: res.hash,
                        balance: state.listReport.price,
                      })
                      .then((resApiSwap: any) => {
                        if (resApiSwap.data.data) {
                          res.wait().then(async (resWaitSC: any) => {
                            if (resWaitSC !== null) {
                              await web3.eth
                                .getTransactionReceipt(res.hash)
                                .then(async (resStatusHash: any) => {
                                  if (resStatusHash.status) {
                                    await actions
                                      .getMintNftUpdate({
                                        mintId: resApiSwap.data.data,
                                        ownerAddress: account,
                                        txnHash: res.hash,
                                      })
                                      .then((resUpdate: any) => {
                                        message.success({
                                          type: "success",
                                          content: "Mint Success",
                                          className: "custom-class add-class",
                                          duration: 2,
                                        });
                                        actions.getHistoryMint({
                                            ownerAddress: account,
                                            lastTime: 0,
                                        })
                                        actions.getAllTeam();
                                        actions.getReport();
                                        setIsLoading(false);
                                      })
                                      .catch((err: any) => {
                                        setIsLoading(false);
                                      });
                                  } else {
                                    setIsLoading(false);
                                  }
                                });
                            }
                        });
                        } else {
                          message.warning({
                            type: "warning",
                            content: "Error Mint from API",
                            className: "custom-class",
                            duration: 2,
                          });
                          setIsLoading(false);
                        }
                      })
                      .catch((err: any) => {
                        setIsLoading(false);
                      });
                  }
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  message.warning({
                    type: "warning",
                    content: error.code,
                    className: "custom-class",
                    duration: 2,
                  });
                });
            } catch (error: any) {
              setIsLoading(false);
      
              message.warning({
                type: "warning",
                content: error.error?.data?.message,
                className: "custom-class",
                duration: 2,
              });
            }
        };

        return (
            <>
                <tr>
                    <td>
                        <div className="box-img">
                            {/* {renderStatus(item.teamId)} */}
                            {item.teamId}
                        </div>
                    </td>
                    <td>
                        <div className="box-score">
                            <img src={item.team?.logoUrl} alt="" />
                            <div className="name">
                                {item.team?.name}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="progess-td">
                            <Tooltip placement="bottom" title={contentTooltips}>
                                <Progress percent={processPercent} showInfo={false} status="active" />
                            </Tooltip>
                        </div>
                    </td>
                    <td>
                        <div className="price">
                            <img src="./favicon-32x32.png" alt="" /> {item.totalMint > 0 ? championPrice : 0} 1SH 
                        </div>
                    </td>
                    <td>
                        {item.holders}
                    </td>
                    <td>
                        {state.listReport.price} 1SH
                    </td>
                    <td>
                        <div className="colspan-button">
                            {!isApprove ? 
                                <>
                                    {isLoading ? 
                                        <>
                                            <button type="button" className="btn-mint btn-mint-dis">
                                                <img src="./images/1shoot/place-dis.png" alt="" />
                                                <SyncOutlined spin />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="button" className="btn-mint" onClick={() => _approveMint() }>
                                                <img src="./images/1shoot/place.png" alt="" />
                                                Approve
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {isLoading ? 
                                        (
                                            <>
                                                <button type="button" className="btn-mint btn-mint-dis">
                                                    <img src="./images/1shoot/place-dis.png" alt="" />
                                                    <SyncOutlined spin />
                                                </button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <button type="button" className="btn-mint" onClick={() => _handleMint() }>
                                                    <img src="./images/1shoot/place.png" alt="" />
                                                    Mint nft
                                                </button>
                                            </>
                                        )
                                    }
                                </>
                            }
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    const ItemMintHistory = ({item , index}:any) => {
        return (
            <>
                <tr>
                    <td className="stt">
                        {index}
                    </td>
                    <td>
                        <div className="box-img-nft">
                            <img src={item.team?.logoUrl} alt="" />
                        </div>
                    </td>
                    <td>
                        {item.team?.name}
                    </td>
                    <td>
                        {item.date}
                    </td>
                    <td>
                        <div className="balance">
                            <img src="./favicon-32x32.png" alt="" /> {item.balance} 1SH
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    const HeaderCollapseMint = (props : any) => {
        const { item } = props;
        return (
            <>
                <div className="header-top-mint">
                    <div className="row-item">
                        <div className="box-img stt">
                            {item.teamId}
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="logo">
                            <img src={item.team?.logoUrl} alt="" />
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="name">
                            {item.team?.name}
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="process">
                            <Progress percent={item.totalMint} showInfo={false} status="active" />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const HeaderCollapseHistory = (props : any) => {
        const { item , index} = props;
        return (
            <>
                <div className="header-top-mint">
                    <div className="row-item">
                        <div className="box-img stt">
                            {index}
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="logo">
                            <img src={item.team?.logoUrl} alt="" />
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="name">
                            {item.team?.name}
                        </div>
                    </div>
                    <div className="row-item price">
                        {item.balance} 1SH
                    </div>
                </div>
            </>
        )
    }

    const RowCollapseMint = (props: any) => {
        const {item} = props;
        const shootContract = useContract(ADDRESS_SHOOT, abiShoot);
        const mintContract = state.listReport.mintNftContract
        const mintNftContract:any = useContract(mintContract , abiMintNFT)
        const [isApprove, setApprove] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (shootContract && account !== undefined) {
              shootContract.allowance(account, mintContract).then((res: any) => {
                if (res.toString() / 1e18 >= state.listReport.price) {
                    setApprove(true);
                } else {
                    setApprove(false);
                }
              });
            }
        }, [account]);

        // _______________handle approce

        const _approveMint = async () => {
            setIsLoading(true);
            try {
              await _approveBUSD(shootContract, mintContract, state.listReport.price).then(
                (res: any) => {
                  res
                    .wait()
                    .then((res1: any) => {
                      if (res1 !== null) {
                        setIsLoading(false);
                        setApprove(true);
                        message.success({
                          type: "success",
                          content: "Approve successfully!",
                          className: "custom-class",
                          duration: 2,
                        });
                        window.location.reload();
                      } else {
                        setIsLoading(false);
                      }
                    })
                    .catch((error: any) => {
                      setIsLoading(false);
                    });
                }
              );
            } catch (error) {
              setIsLoading(false);
            }
        };

        // _________________handle mint NFT

        const web3 = new Web3(
            new Web3.providers.HttpProvider("https://bsc-dataseed1.binance.org/")
        );

        const _handleMint = async () => {
            setIsLoading(true);
            try {
              await _buyMysteryBox(mintNftContract , state.listReport.campaignId , item.tokenId)
                .then(async (res: any) => {
                  if (res.hash) {
                    await actions
                      .getMintNft({
                        teamId: item.teamId,
                        ownerAddress: account,
                        txnHash: res.hash,
                        balance: state.listReport.price,
                      })
                      .then((resApiSwap: any) => {
                        if (resApiSwap.data.data) {
                          res.wait().then(async (resWaitSC: any) => {
                            if (resWaitSC !== null) {
                              await web3.eth
                                .getTransactionReceipt(res.hash)
                                .then(async (resStatusHash: any) => {
                                  if (resStatusHash.status) {
                                    await actions
                                      .getMintNftUpdate({
                                        mintId: resApiSwap.data.data,
                                        ownerAddress: account,
                                        txnHash: res.hash,
                                      })
                                      .then((resUpdate: any) => {
                                        message.success({
                                          type: "success",
                                          content: "Mint Success",
                                          className: "custom-class add-class",
                                          duration: 2,
                                        });
                                        actions.getHistoryMint({
                                            ownerAddress: account,
                                            lastTime: 0,
                                        })
                                        actions.getAllTeam();
                                        actions.getReport();
                                        setIsLoading(false);
                                      })
                                      .catch((err: any) => {
                                        setIsLoading(false);
                                      });
                                  } else {
                                    setIsLoading(false);
                                  }
                                });
                            }
                        });
                        } else {
                          message.warning({
                            type: "warning",
                            content: "Error Mint from API",
                            className: "custom-class",
                            duration: 2,
                          });
                          setIsLoading(false);
                        }
                      })
                      .catch((err: any) => {
                        setIsLoading(false);
                      });
                  }
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  message.warning({
                    type: "warning",
                    content: error.code,
                    className: "custom-class",
                    duration: 2,
                  });
                });
            } catch (error: any) {
              setIsLoading(false);
      
              message.warning({
                type: "warning",
                content: error.error?.data?.message,
                className: "custom-class",
                duration: 2,
              });
            }
        };
        return (
            <>
                <div className="colum-mint">
                    <div className="item">
                        <div className="txt-l">
                            championship prize:
                        </div>
                        <div className="txt-r">
                            <div className="price">
                                <img src="./favicon-32x32.png" alt="" /> {item.championshipPrize} 1SH
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            holders:
                        </div>
                        <div className="txt-r">
                            {item.holders}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Floor prize:
                        </div>
                        <div className="txt-r">
                            {item.floorPrize} 1SH
                        </div>
                    </div>
                    <div className="item center">
                        <div className="colspan-button">
                            {!isApprove ? 
                                <>
                                    {isLoading ? 
                                        <>
                                            <button type="button" className="btn-mint btn-mint-dis">
                                                <img src="./images/1shoot/place-dis.png" alt="" />
                                                <SyncOutlined spin />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="button" className="btn-mint" onClick={() => _approveMint() }>
                                                <img src="./images/1shoot/place.png" alt="" />
                                                Approve
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {isLoading ? 
                                        (
                                            <>
                                                <button type="button" className="btn-mint btn-mint-dis">
                                                    <img src="./images/1shoot/place-dis.png" alt="" />
                                                    <SyncOutlined spin />
                                                </button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <button type="button" className="btn-mint" onClick={() => _handleMint() }>
                                                    <img src="./images/1shoot/place.png" alt="" />
                                                    Mint nft
                                                </button>
                                            </>
                                        )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const RowCollapseHistory = (props: any) => {
        const {item} = props;
        return (
            <>
                <div className="colum-mint">
                    <div className="item">
                        <div className="txt-l">
                            Time:
                        </div>
                        <div className="txt-r">
                            <div className="price">
                                {item.date}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            balance:
                        </div>
                        <div className="txt-r">
                            <div className="price">
                                {item.balnace} 1SH
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="content-champion">
                <div className="champion-top">
                    <div className="left">
                        <div className="title-cp">
                            mint team, <br />
                            be the <span className="main-color-cp">champion</span>
                        </div>
                        <div className="desc-cp">
                            Mint NFTs with <span className="fw-bold">1000 1SH</span> token for a chance to win big prizes. 1Shoot adds <span className="fw-bold">900 1SH</span> to the prize pool for <span className="fw-bold">each</span> NFT minted and <span className="fw-bold">100 1SH</span> of <span className="fw-bold">each</span> NFT Minted <span className="fw-bold">will be burnt</span> after the Final Match. Support your favourite football team today!
                        </div>
                        <div className="total-prize">
                            Total prize 
                            <Tooltip placement="bottom" title="We will add  900 1sh (90%) to the pool prize with each NFT minted. 100 1sh (10%) for each NFT minted will be used for burning after the Final Match.">
                                <img src="./images/1shoot/info-1s.png" alt="" />
                            </Tooltip>
                        </div>
                        <div className="number-prize">
                            {state.listReport?.prizePool} 1SH
                        </div>
                        <div className="rules-cp">
                            <button type="button" className="btn-mint" onClick={handleClaimScroll}>
                                <img src="./images/1shoot/place-dis.png" alt="" />
                                Mint nft
                            </button>
                            <div className="rules-content" onClick={showModal}>
                                <img src="./images/1shoot/rules.png" alt="" />
                                <span>Rules</span>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="box-img">
                            <img src="./images/1shoot/img-champion.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="champion-center">
                    <div className="img">
                        <img src="./images/1shoot/vector.png" alt="" />
                    </div>
                    <div className="left">
                        <div className="title-cp">
                            Mint and root for your teams
                        </div>
                        <ul className="list-total">
                            <li>
                                <div className="txt-text">
                                    Total minted:
                                </div>
                                <div className="txt-number">
                                    {state.listReport?.totalMint}
                                </div>
                            </li>
                            <li>
                                <div className="txt-text">
                                    Total holders:
                                </div>
                                <div className="txt-number">
                                    {state.listReport?.totalHolders}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <div className="txt">
                            Mint Closing Countdown: 
                            <div className="time">
                                <Countdown
                                date={convertDateTime(startUtc)}
                                renderer={rendererCountDown}
                                // onComplete={handleCompleteTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="champion-bottom">
                    {isMobile ? 
                        <>
                            <Collapse onChange={onChange} className="collapse-mint">
                                {state?.listAllTeam &&
                                state?.listAllTeam.map((item: any, index: any) => (
                                    <Panel header={<HeaderCollapseMint item={item} key={index.toString()} />}>
                                        <RowCollapseMint item ={item} />
                                    </Panel>
                                ))}
                            </Collapse>
                        </>
                        :
                        <>
                            <div className="main-table">
                                <table>
                                    <tr>
                                        <th>
                                            {""}
                                        </th>
                                        <th>
                                            Team nft
                                        </th>
                                        <th>
                                            Total minted
                                        </th>
                                        <th>
                                            <Tooltip placement="bottom" title="Prizes you may get for each NFT if the team becomes the 2022 World Cup Champion">
                                                championship prize <img src="./images/1shoot/info-1s.png" alt="" />
                                            </Tooltip>
                                        </th>
                                        <th>
                                            holders
                                        </th>
                                        <th>
                                            Floor prize
                                        </th>
                                        <th>
                                            {""}
                                        </th>
                                    </tr>
                                    {state?.listAllTeam &&
                                        state?.listAllTeam.map((item: any, index: any) => (
                                        <>
                                            <ItemMint item={item} />
                                        </>
                                    ))}
                                </table>
                            </div>
                        </>
                    }
                </div>
                <div className="champion-ntf">
                    <div className="title-cp">
                        My nfts
                        <div className="img">
                            <img src="./images/1shoot/vector.png" alt="" />
                        </div>
                    </div>
                    {account === undefined ?
                        <>
                            <div className="round-history-non-account">
                                <div className="wallet">
                                    <img src="./images/1shoot/wallet-add.svg" alt="" />
                                </div>
                                <div className="desc">
                                    Connect wallet to check out your NFTs
                                </div>
                                <div className="connect-wallet-logs">
                                    <div className="res-btn-connect">
                                        <img src="./images/1shoot/place.png" alt="" />
                                        <ConnectWallet />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {isMobile ? 
                                <>
                                    <Collapse onChange={onChange} className="collapse-mint">
                                        {state?.listHistoryMint &&
                                        state?.listHistoryMint.map((item: any, index: any) => (
                                            <Panel header={<HeaderCollapseHistory item={item} index={index} key={index.toString()} />}>
                                                <RowCollapseHistory item ={item} />
                                            </Panel>
                                        ))}
                                    </Collapse>
                                </>
                                :
                                <>
                                    <div className="main-table-history-mint">
                                        <table>
                                            <tr>
                                                <th>
                                                    {""}
                                                </th>
                                                <th>
                                                    Team NFT
                                                </th>
                                                <th>
                                                    Name NFT
                                                </th>
                                                <th>
                                                    Date
                                                </th>
                                                <td>
                                                    Balance
                                                </td>
                                            </tr>
                                            {state?.listHistoryMint.length === 0 ?
                                                <>
                                                    <tr className="non-data-mint">
                                                        <td colsPan={5}>
                                                            <div className="non-data-mint-his">
                                                                <img src="./images/bet/non-data.png" alt="" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    {state?.listHistoryMint &&
                                                        state?.listHistoryMint.map((item: any, index: any) => (
                                                        <>
                                                            <ItemMintHistory index={index} item={item} />
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </table>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <Modal title="Rules" className="modal-rules" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {contentRules}
            </Modal>
        </>
    )
}
export default ChampionShoot