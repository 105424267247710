import React, { FC } from "react";
import Footer from "./Footer";
import Header from "./Header";

interface LayoutDefaultType {
  children: React.ReactNode;
}

const LayoutDefault: FC<LayoutDefaultType> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      {/* <Footer /> */}
    </>
  );
};

export default LayoutDefault;
