
export enum STATUS_BET {
    WAITING=0,
    WIN = 1,
    DRAW=2,
    LOST = 3
}
  
export enum STATUS_HIS {
    WAITING='Waiting',
    WIN = 'Win',
    LOST = 'Lose'
}
export enum COMBAT_TYPE {
  A_WIN=1,
  DRAW = 2,
  B_WIN = 3
}
export enum SCORE_TYPE {
  ONE = 1,
  TWO = 2,
  THREE = 3
}