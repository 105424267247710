import React, { useEffect } from "react";
import FootballBet from "./pages/FootballBet";
import Main from "./component/main/Main";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext";
import { WrapperProvider } from "./context/WrapperContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <RefreshContextProvider>
            <Main>
              <Routes>
                <Route path="/" element={<FootballBet />} />
              </Routes>
            </Main>
          </RefreshContextProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </BrowserRouter>
  );
}

export default App;
