/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import encodeAddress from "../../utils/encodeData";

import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";

const StoreGameBet = createStore({
  initialState: {
    listCalendar: [],
    listWorldcups: [],
    listSetting: {},
    listHistory: [],
    listHistoryMore: [],
    listAllTeam: [],
    listReport: {},
    listHistoryMint: [],
  },
  actions: {
    getCalendar:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/calendar`, params).then((res) => {
            setState({ listCalendar: res.data.data || [] });
            resolve(true);
          });
        });
      },
    getWorldcups:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/worldcups`, params).then((res) => {
            setState({ listWorldcups: res.data.data || [] });
            resolve(true);
          });
        });
      },
    getSetting:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.get(`${API_URL}/oneshoot/setting`, params).then((res) => {
            setState({ listSetting: res.data.data || {} });
            resolve(true);
          });
        });
      },
    getHistory:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/history`, params).then((res) => {
            setState({ listHistory: res.data.data || [] });
            resolve(true);
          });
        });
      },
    getHistoryMore:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/history`, params).then((res) => {
            setState({
              listHistoryMore:
                getState().listHistoryMore.concat(res.data.data) || [],
            });

            const {
              data: { data },
            } = res;
            resolve(data);
          });
        });
      },
    getDepositBet:
      (obj) =>
      async ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${API_URL}/oneshoot/deposit`,
              {
                ownerAddress: obj.ownerAddress,
                balance: obj.balance,
                tnxHash: obj.tnxHash,
                combatId: obj.combatId,
                combatType: obj.combatType,
                numberTicket: obj.numberTicket,
              },
              {
                headers: {
                  "bscs-token": encodeAddress(
                    JSON.stringify({
                      ownerAddress: obj.ownerAddress,
                      tnxHash: obj.tnxHash,
                      combatId: obj.combatId,
                      combatType: obj.combatType,
                      numberTicket: obj.numberTicket,
                    }).toLocaleLowerCase()
                  ),
                },
              }
            )
            .then((res) => {
              resolve(res);
            });
        });
      },
    updateWithdraw:
      (params) =>
      async ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/withdraw`, params).then((res) => {
            resolve(res);
          });
        });
      },
    getDepositBetUpdate:
      (obj) =>
      async ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${API_URL}/oneshoot/update_deposit`,
              {
                ...obj,
              },
              {
                headers: {
                  "bscs-token": encodeAddress(
                    JSON.stringify({
                      depositId: obj.depositId,
                      ownerAddress: obj.ownerAddress,
                      tnxHash: obj.tnxHash,
                    }).toLocaleLowerCase()
                  ),
                },
              }
            )
            .then((res) => {
              resolve(res);
            });
        });
      },
      getAllTeam:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.get(`${API_URL}/nft/allteammint`, params).then((res) => {
            setState({ listAllTeam: res.data.data || [] });
            resolve(true);
          });
        });
      },
      getReport:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.get(`${API_URL}/nft/report`, params).then((res) => {
            setState({ listReport: res.data.data || {} });
            resolve(true);
          });
        });
      },
      getMintNft:
      (obj) =>
      async ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${API_URL}/nft/mint_nft`,
              {
                teamId:obj.teamId,
                ownerAddress: obj.ownerAddress,
                txnHash: obj.txnHash,
                balance: obj.balance,
              },
              {
                headers: {
                  "bscs-token": encodeAddress(
                    JSON.stringify({
                      teamId:obj.teamId,
                      ownerAddress: obj.ownerAddress,
                      txnHash: obj.txnHash,
                    }).toLocaleLowerCase()
                  ),
                },
              }
            )
            .then((res) => {
              resolve(res);
            });
        });
      },
      getMintNftUpdate:
      (obj) =>
      async ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${API_URL}/nft/update_mint_nft`,
              {
                ...obj,
              },
              {
                headers: {
                  "bscs-token": encodeAddress(
                    JSON.stringify({
                      mintId: obj.mintId,
                      ownerAddress: obj.ownerAddress,
                      txnHash: obj.txnHash,
                    }).toLocaleLowerCase()
                  ),
                },
              }
            )
            .then((res) => {
              resolve(res);
            });
        });
      },
      getHistoryMint:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/nft/history`, params).then((res) => {
            setState({ listHistoryMint: res.data.data || [] });
            resolve(true);
          });
        });
      },
  },

  name: "Game Bet Store",
});

export const useHookGameBet = createHook(StoreGameBet);
export const Container = createContainer(StoreGameBet, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreGameBet);
