/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import encodeAddress from "../../utils/encodeData";

import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";

const StoreGameBetMore = createStore({
  initialState: {
    listCalendar: [],
    listWorldcups: [],
    listSetting: {},
    listHistory: [],
    listHistoryMore: [],
  },
  actions: {
    getHistoryMore:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/history`, params).then((res) => {
            setState({
              listHistoryMore:
                getState().listHistoryMore.concat(res.data.data) || [],
            });

            const {
              data: { data },
            } = res;
            resolve(data);
          });
        });
      },
    getWorldcups:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/oneshoot/worldcups`, params).then((res) => {
            setState({ listWorldcups: res.data.data || [] });
            resolve(true);
          });
        });
      },
  },

  name: "Game Bet Store More",
});

export const useHookGameBetMore = createHook(StoreGameBetMore);
export const Container = createContainer(StoreGameBetMore, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreGameBetMore);
