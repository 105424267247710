import React, { useEffect, memo } from "react";
import { useCookies } from "react-cookie";
import encodeAddress from "../../utils/encodeData";
import { useActiveWeb3React } from "../../hooks";
import { useWeb3React } from "@web3-react/core";
import { Layout } from "antd";

const { Content } = Layout;
function Main({ children }: any) {
  const [cookies, setCookie] = useCookies(["user" , "account"]);
  const { account } = useActiveWeb3React();

  useEffect(() => {
    if (account) {
      setCookie("user", encodeAddress(account));
      setCookie("account", account);
    } else {
      setCookie("user", null);
      setCookie("account", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Layout>
      <Content>{children}</Content>
    </Layout>
  );
}

export default Main;
