/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tabs } from 'antd';
import ChampionShoot from "./ChampionShoot"
import BetShoot from "./BetShoot";
import LayoutDefault from "../../component/common/LayoutDefault";

const FootballBet = () => {
    return (
        <> 
            <LayoutDefault>
                <div className="main-bet-wrapper">
                    <img src="./images/1shoot/props.png" alt="" className="props" />
                    <div className="container-foob">
                        <div className="content-bet-top">
                            <div className="box-img-title">
                                <img src="./images/1shoot/title-1s.png" alt="" />
                            </div>
                        </div>
                        <Tabs defaultActiveKey="2" className="tabs-worldcup">
                            <Tabs.TabPane tab="Match Prediction" key="1">
                                <BetShoot />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Who is Champion" key="2">
                                <ChampionShoot />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </LayoutDefault>
        </>
    )
}
export default FootballBet