import React, { useEffect, useState } from "react";
import web3 from "web3";
import { Modal, Dropdown, message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useConnectWallet, useActiveWeb3React } from "../../hooks";
import { NEED_A_PLACEHOLDER, WALLET_LIST } from "../../constants/index";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS, ADDRESS_USD } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";
import abiBUSD from "../../abi/abiBUSD.json";
import WalletItem from "./WalletItem";
import { useWeb3React } from "@web3-react/core";
import "antd/dist/antd.min.css";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { async } from "@firebase/util";
import bgWallet from "./images/bg-wl.png"

declare const window: Window & typeof globalThis & { ethereum: any };
const ConnectWallet = (props: any) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { walletLogin, walletLogout } = useConnectWallet();
  const walletItemClass = "w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent";
  const { account } = useActiveWeb3React();
  const { chainId }: any = useWeb3React();
  const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
  const busdContract: any = useContract(ADDRESS_USD, abiBUSD);
  const context = useWeb3React();
  const { active } = context;
  const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  const [amountBscs, setAmountBscs] = useState(0);
  const [amountBusd, setAmountBusd] = useState(0);
  let location = useLocation();
  const pathLive = location.pathname.split("/");
  const pathLiveCenter = pathLive[1];
  const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
  const [bnbBalance, setBnbBalance] = useState(0);

  useEffect(() => {
    if (account) {
      w3.eth.getBalance(account, (err, balance) => {
        if (balance) {
          let _balance: any = web3.utils.fromWei(
            web3.utils.toBN(balance),
            "ether"
          );
          let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
          setBnbBalance(bnbBl);
        }
      });
      getBscs();
      getBusd();
    }
  }, [account]);

  const getBusd = async () => {
    if (busdContract) {
      const amount = await busdContract.balanceOf(account);
      setAmountBusd(amount.toString() / 1e18);
    }
  };

  const getBscs = async () => {
    if (bscsContract) {
      const amount = await bscsContract.balanceOf(account);
      setAmountBscs(amount.toString() / 1e18);
    }
  };

  // --- ACTION IN MODAL ---
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //  --- Return address wallet with substring ---

  const accountEllipsisRow = active
    ? `${account?.substring(0, 8)}...${account?.substring(account.length - 8)}`
    : "Connect Wallet ";

  // ---- HANDLE DISCONNECT ----
  const handleDisconnect = () => {
    walletLogout();
    setIsModalVisible(false);
  };

  // ---- HANDLE CONNECT ----
  const handleConnect = async (connectorId: string) => {
    try {
      const rs = await walletLogin(connectorId);
      setIsModalVisible(false);
    } catch (e) {
      console.error("Login failed");
    }
  };

  const network_url =
    chainId === 56
      ? "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
      : chainId === 1
      ? "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/816fa85de2c761a9dac2e5770f0b08738177d6995efda00fee.png"
      : "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/9970ad6f8e2d9b36ab187859b143093a544eaf1f69c3202e0a.png";
  const network_chain =
    chainId === 56 ? "BNB" : chainId === 1 ? "ETH" : "MATIC";


  return (
    <>
      {active ? (
        <>
          <button
            className="btn-connect-now"
            type="button"
          >
            <a href="https://1shoot.games/app">
              Play Now
            </a>
          </button>
        </>
      ) : (
        <>
          <>
            <button className="btn-connect" onClick={showModal}>
              Connect Wallet 
            </button>
          </>
        </>
      )}
      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        // title={
        //   <div className="text-md connect-wallet-title">
        //     {!account ? "Select Wallet" : ""}
        //   </div>
        // }
        style={{ 
          backgroundImage: `url(${bgWallet})` 
        }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="connect-wallet"
      >
        {!active ? (
          <>
            <div className="flex flex-wrap">
              {WALLET_LIST.map((wallet) => {
                return (
                  <WalletItem
                    className={`wallet-item`}
                    key={wallet.title}
                    onClick={() => handleConnect(wallet.connectorId)}
                    icon={<wallet.icon width="48px" />}
                    title={wallet.title}
                  />
                );
              })}
              {NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
            </div>
          </>
        ) : (
          <>
            <div className="noti-out">
						<p className="noti-out-text">Disconnect this contract?</p>
						<div className="noti-out-button">
							<button className="btnConnect" onClick={handleDisconnect}>
								Disconnect
							</button>
						</div>
					</div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ConnectWallet;
