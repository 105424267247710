/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React , {useState} from "react";
import "./style.css"
import {  Drawer } from 'antd';
import { useLocation } from "react-router-dom";
import ConnectWallet from "../../component/Connect-wallet/ConnectWallet"
import { useActiveWeb3React } from "../../hooks";
import { isMobile } from "react-device-detect";

const Header = () => {
    const { account } = useActiveWeb3React();
    let location = useLocation();
    const pathLive = location.pathname.split("/");
    const pathLiveCenter = pathLive[1];
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            {isMobile ? 
                <>
                    <header className="header-mobile">
                        <div className="container-m">
                            <div className="main-header-mobile">
                                <div className="header-l">
                                    <div className="box-logo-bet">
                                        <a href="https://1shoot.games/">
                                            <img src="./images/1shoot/logo-1s.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="header-r">
                                    <button type="button" className="show-menu" onClick={showDrawer}>
                                        <img src="./images/1shoot/menu.png" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Drawer title="Basic Drawer" className="drawer-menu" placement="right" onClose={onClose} open={open}>
                            <div className="top-drawer">
                                <div className="left">
                                    <div className="box-logo-bet">
                                        <a href="https://1shoot.games/">
                                            <img src="./images/1shoot/logo-1s.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="right">
                                    <button type="button" className="show-menu" onClick={onClose}>
                                        X
                                    </button>
                                </div>
                            </div>
                            <div className="content-drawer">
                                <ul className="list-menu-draw">
                                    <li>
                                        <a href="#">
                                            Your Wallet
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Referrals
                                        </a>
                                    </li>
                                </ul>
                                <div className="btn-group-menu">
                                    <button type="button" className="buy-1shot">
                                        Buy 1SH
                                    </button>
                                    <ConnectWallet />
                                </div>
                                <div className="group-social">
                                    <a href="#">
                                        <img src="./images/1shoot/twi.png" alt="" />
                                    </a>
                                    <a href="#">
                                        <img src="./images/1shoot/tele.png" alt="" />
                                    </a>
                                    <a href="#">
                                        <img src="./images/1shoot/dis.png" alt="" />
                                    </a>
                                </div>
                                <div className="group-ball">
                                    <img src="./images/1shoot/ball.png" alt="" />
                                </div>
                                <div className="copy-right">
                                    1Shoot 	&#169; 2022 All Rights Reserved.
                                </div>
                            </div>
                        </Drawer>
                    </header>
                </>
                :
                <>
                    <header className="header-foob bet">
                        <div className="container-foob">
                            <div className="main-header">
                                <div className="header-l">
                                    <div className="list-menu">
                                        <li>
                                            <a href="https://1shoot.games/your-wallet">
                                                Your Wallets
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://1shoot.games/referrals">
                                                Referrals
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" target="_blank">
                                                Profile
                                            </a>
                                        </li> */}
                                        <li className="active">
                                            WC2022
                                        </li>
                                    </div>
                                </div>
                                <div className="header-center">
                                    <div className="box-logo-bet">
                                        <a href="https://1shoot.games/">
                                            <img src="./images/1shoot/logo-1s.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="header-r">
                                    <div className="gr-button">
                                        <button type="button" className="buy-1shot">
                                            <a href="https://pancakeswap.finance/swap?outputCurrency=0x2AF1096b974204e3d6e52318Ab807cB40d1846bE&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" target="_blank">
                                                Buy 1SH
                                            </a>
                                        </button>
                                        <ConnectWallet />
                                        {account !== undefined ? 
                                            <>
                                                <div className="wallet-1s">
                                                    <a href="https://1shoot.games/your-wallet">
                                                        <img src="./images/1shoot/wl.png" alt="" />
                                                    </a>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
            </header>
                </>
            }
        </>
    )
}
export default Header