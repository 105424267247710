/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo, useCallback, useRef } from "react";
import {
  Radio,
  Tooltip,
  message,
  Skeleton,
  Spin,
  Empty,
  Collapse,
  Pagination,
} from "antd";
import { CProgress, CProgressBar } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";
import Countdown from "react-countdown";
import moment from "moment";
import Web3 from "web3";
import { isMobile } from "react-device-detect";
import { SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import NumericInput from "react-numeric-input";
import { useActiveWeb3React } from "../../hooks";
import { useContract } from "../../hooks/useContract";
import abiBUSD from "../../abi/abiBUSD.json";
import abiShoot from "../../abi/abiShoot.json";
import abiBet from "../../abi/abiBet.json";
import { ADDRESS_USD, ADDRESS_SHOOT } from "../../constants";
import Slider from "react-slick";
import ConnectWallet from "../../component/Connect-wallet/ConnectWallet";
import { useHookGameBet } from "./StoreBet";
import { useHookGameBetMore } from "./StoreBetMore";
import {
  _approveBUSD,
  _getDepositSwap,
  _claim,
  isClaimed,
  _isJoined,
} from "./utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { STATUS_BET, STATUS_HIS, COMBAT_TYPE } from "./index.d";

const SliderBet = memo(({ setCrr }: any) => {
  const [state, actions]: any = useHookGameBet();
  const { account } = useActiveWeb3React();

  let arrayCalendar = state.listCalendar.find(
    (item: any) => item.isFinish === true
  );
  let arrayCalendarIndex = state.listCalendar.indexOf(arrayCalendar);
  let params = {
    nextTime: 0,
    prvTime: 0,
  };

  let paramsWC = {
    ownerAddress: account,
    combatDate: arrayCalendar?.time,
  };

  useEffect(() => {
    actions.getCalendar(params);
    actions.getSetting();
  }, []);

  useEffect(() => {
    if (account && paramsWC.combatDate !== undefined) {
      actions.getWorldcups(paramsWC);
    }
  }, [account, paramsWC.combatDate]);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SampleNextArrow = (props: any) => {
    const { onClick } = props;
    setCrr(props.currentSlide);
    return (
      <div className="button-img-l" onClick={onClick}>
        <img src="./images/1shoot/r-l.png" alt="" />
      </div>
    );
  };

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <div className="button-img-r" onClick={onClick}>
        <img src="./images/1shoot/r-r.png" alt="" />
      </div>
    );
  }

  const ItemTop = ({ item }: any) => {
    const handleClick = (data: any) => {
      paramsWC.combatDate = data;
      // actions.getWorldcups(paramsWC);
    };
    return (
      <>
        <div className="item" onClick={() => handleClick(item.time)}>
          <div className="group-stage">Group stage</div>
          <div className="time-stage">{item.date}</div>
        </div>
        {/* {item.isFinish ? 
          <>
            <div className="item" onClick={() => handleClick(item.time)}>
              <div className="group-stage">Group stage</div>
              <div className="time-stage">{item.date}</div>
            </div>
          </>
          :
          <>
            {""}
          </>
        } */}
      </>
    );
  };

  const ItemBottom = ({ item }: any) => {
    const [value, setValue] = useState();
    const [quantity, setQuantity]: any = useState(1);
    const [isSke, setIsSke] = useState(true);
    const amountBet = quantity * state.listSetting.betAmount;
    const [isApproveSwap, setApproveSwap] = useState(false);
    const [isBet, setIsBet] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const shootContract = useContract(ADDRESS_SHOOT, abiShoot);
    const betContract = state.listSetting.withdrawContract;
    const swapContract: any = useContract(betContract, abiBet);
    const amountBeted = item.joined?.numberTicket * state.listSetting.betAmount;
    const onChange = (e: any) => {
      setValue(e.target.value);
    };
    setTimeout(() => {
      setIsSke(false);
    }, 300);
    // handle check approve

    useEffect(() => {
      if (shootContract && account !== undefined) {
        shootContract.allowance(account, betContract).then((res: any) => {
          if (res.toString() / 1e18 > amountBet) {
            setApproveSwap(true);
          } else {
            setApproveSwap(false);
          }
        });
      }
    }, [account]);

    useEffect(() => {
      if (swapContract && account !== null) {
        checkJoned();
      }
    }, [account, swapContract, item.combatId]);

    const checkJoned = async () => {
      await _isJoined(swapContract, account, item.combatId).then((res: any) => {
        if (res.toString() / 1e18 > 0) {
          setIsBet(true);
        } else {
          setIsBet(false);
        }
      });
    };

    // handle approve trước khi join

    const _approveJoinBet = async () => {
      setIsLoading(true);
      try {
        await _approveBUSD(shootContract, betContract, amountBet).then(
          (res: any) => {
            res
              .wait()
              .then((res1: any) => {
                if (res1 !== null) {
                  setIsLoading(false);
                  setApproveSwap(true);
                  message.success({
                    type: "success",
                    content: "Approve successfully!",
                    className: "custom-class",
                    duration: 2,
                  });
                  window.location.reload();
                } else {
                  setIsLoading(false);
                }
              })
              .catch((error: any) => {
                setIsLoading(false);
              });
          }
        );
      } catch (error) {
        setIsLoading(false);
      }
    };

    // handle join bet
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://bsc-dataseed1.binance.org/")
    );
    const handleJoinBet = async () => {
      setIsLoading(true);
      // await actions;
      try {
        await _getDepositSwap(swapContract, amountBet, item.combatId)
          .then(async (res: any) => {
            if (res.hash) {
              await actions
                .getDepositBet({
                  ownerAddress: account,
                  balance: amountBet,
                  tnxHash: res.hash,
                  combatId: item.combatId,
                  combatType: value,
                  numberTicket: quantity,
                })
                .then((resApiSwap: any) => {
                  if (resApiSwap.data.data) {
                    res.wait().then(async (resWaitSC: any) => {
                      if (resWaitSC !== null) {
                        await web3.eth
                          .getTransactionReceipt(res.hash)
                          .then(async (resStatusHash: any) => {
                            if (resStatusHash.status) {
                              await actions
                                .getDepositBetUpdate({
                                  depositId: resApiSwap.data.data,
                                  ownerAddress: account,
                                  tnxHash: res.hash,
                                })
                                .then((resUpdate: any) => {
                                  if (resUpdate.data.data === true) {
                                    checkJoned();
                                  }
                                  message.success({
                                    type: "success",
                                    content: "Bet Success",
                                    className: "custom-class add-class",
                                    duration: 2,
                                  });
                                  let paramHis = {
                                    ownerAddress: account,
                                    lastTime: 0,
                                  };
                                  actions.getWorldcups(paramsWC).then(() => {
                                    setIsLoading(false);
                                    setIsBet(true);
                                    actions.getHistory(paramHis);
                                  });
                                })
                                .catch((err: any) => {
                                  setIsLoading(false);
                                });
                            } else {
                              setIsLoading(false);
                            }
                          });
                      }
                    });
                  } else {
                    message.warning({
                      type: "warning",
                      content: "Error Swap from API",
                      className: "custom-class",
                      duration: 2,
                    });
                    setIsLoading(false);
                  }
                })
                .catch((err: any) => {
                  setIsLoading(false);
                });
            }
          })
          .catch((error: any) => {
            setIsLoading(false);
            message.warning({
              type: "warning",
              content: error.code,
              className: "custom-class",
              duration: 2,
            });
          });
      } catch (error: any) {
        setIsLoading(false);

        message.warning({
          type: "warning",
          content: error.error?.data?.message,
          className: "custom-class",
          duration: 2,
        });
      }
    };

    const handleClaimScroll = () => {
      window.scrollTo({ top: 700, behavior: "smooth" });
    };

    const renderTextBottom = (status: any) => {
      switch (status) {
        case STATUS_BET.WIN:
          return (
            <>
              {item.joined !== null ? (
                item.joined?.winBalance > 0 ? (
                  <div className="comeback-result">
                    Congratulation. You win{" "}
                    <span className="main-color-bet">
                      {item.joined?.winBalance}
                    </span>{" "}
                    1SH . Click to&nbsp;
                    <span className="claim-scroll" onClick={handleClaimScroll}>
                      Claim
                    </span>{" "}
                    your rewards
                  </div>
                ) : (
                  <div className="comeback-result">
                    Congratulation. Click to&nbsp;
                    <span className="claim-scroll" onClick={handleClaimScroll}>
                      Claim
                    </span>{" "}
                    your rewards
                  </div>
                )
              ) : (
                ""
              )}
            </>
          );
          break;
        case STATUS_BET.LOST:
          return <div className="comeback-result">You have lost your bet</div>;

          break;
        case STATUS_BET.DRAW:
          return <div className="comeback-result"> You have draw your bet</div>;

          break;
        case STATUS_BET.WAITING:
          return (
            <div className="comeback-result">
              Come back later for match result.
            </div>
          );
          break;
      }
    };

    const renderText = (type: any) => {
      switch (type) {
        case COMBAT_TYPE.A_WIN:
          return (
            <>
              You have placed bet{" "}
              <span className="main-color-bet">{amountBeted} 1SH </span> on{" "}
              {item.teamA?.name} Win
            </>
          );
        case COMBAT_TYPE.DRAW:
          return (
            <>
              You have placed bet{" "}
              <span className="main-color-bet">{amountBeted} 1SH </span> on Draw
            </>
          );
        case COMBAT_TYPE.B_WIN:
          return (
            <>
              You have placed bet{" "}
              <span className="main-color-bet">{amountBeted} 1Sh </span> on{" "}
              {item.teamB?.name} Win
            </>
          );
      }
    };

    const renderClass = (text: any) => {
      switch (text) {
        case "Live":
          return "liveTime";
          break;
        case "Ended":
          return "endTime";

          break;
        default: {
          return "time";
          break;
        }
      }
    };

    let sliceArrayA = item.teamA.name.slice(0, 3).toUpperCase();
    let sliceArrayB = item.teamB.name.slice(0, 3).toUpperCase();

    const startTimeNow = new Date(item.utcTime * 1000);
    const startUtc = startTimeNow;
    const convertDateTime = (startUtc: any) => {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
        timeZoneName: "short",
        hour12: true,
      };
      return startUtc.toLocaleTimeString("en-GB", options);
    };

    const rendererCountDown = ({
      days,
      hours,
      minutes,
      seconds,
      completed,
    }: any) => {
      // Render a countdown
      if (days > 1) {
        return (
          <span>
            {days} : {hours} : {minutes} : {seconds}
          </span>
        );
      } else if (days === 1) {
        return (
          <span>
            {days} : {hours} : {minutes} : {seconds}
          </span>
        );
      } else {
        return (
          <span>
            {hours} : {minutes} : {seconds}
          </span>
        );
      }
    };

    return (
      <>
        <div className="colum w-4">
          {isSke ? (
            <Skeleton className="ske-bet" paragraph={{ rows: 13 }} />
          ) : (
            <>
              <div className="guide-bet">
                <div className="guide-top">
                  <div className={renderClass(item.time)}>{item.time}</div>
                  <div className="price-pool">
                    Prize pool:{" "}
                    <span className="main-color-bet cus">
                      {item.prizePool} 1SH
                    </span>
                  </div>
                </div>
                <div className="guide-mid">
                  <div className="battle-score">
                    <div className="item-left">
                      <div className="box-img">
                        <img src={item.teamA.logoUrl} alt="" />
                      </div>
                      <div className="txt">{item.teamA.name}</div>
                    </div>
                    <div className="item-mid">
                      <div className="box-img">
                        <img src="./images/bet/vs.svg" alt="" />
                      </div>
                      <div className="txt-handicap">Handicap</div>
                      <div className="txt">
                        {item.teamA.rate} : {item.teamB.rate}
                      </div>
                    </div>
                    <div className="item-right">
                      <div className="box-img">
                        <img src={item.teamB.logoUrl} alt="" />
                      </div>
                      <div className="txt">{item.teamB.name}</div>
                    </div>
                  </div>
                </div>
                {isBet ? (
                  <>
                    <div className="bet-sorce-two-team">
                      <div className="item-team">{item.teamA.value}</div>
                      <span>:</span>
                      <div className="item-team">{item.teamB.value}</div>
                    </div>
                  </>
                ) : (
                  <>
                    {item.time === "Ended" || item.time === "Live" ? (
                      <>
                        <div className="bet-sorce-two-team">
                          <div className="item-team">{item.teamA.value}</div>
                          <span>:</span>
                          <div className="item-team">{item.teamB.value}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="guide-bottom">
                          <div className="bet-result">
                            <Radio.Group onChange={onChange} value={value}>
                              <Radio value={1}>{sliceArrayA} win</Radio>
                              <Radio value={2}>Draw</Radio>
                              <Radio value={3}>{sliceArrayB} win</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="process-line">
                  <div className="title">PREDICTION RATIO</div>
                  <div className="bet-round-line">
                    <div className="item">
                      {sliceArrayA} Win{" "}
                      <span className="percent">{item.prediction?.win}%</span>
                    </div>
                    <div className="item">
                      Draw{" "}
                      <span className="percent">{item.prediction?.draw}%</span>
                    </div>
                    <div className="item">
                      {sliceArrayB} Win{" "}
                      <span className="percent">{item.prediction?.lose}%</span>
                    </div>
                  </div>
                  <CProgress className="mb-3">
                    <CProgressBar
                      color="warning"
                      value={item.prediction?.win}
                    />
                    <CProgressBar
                      color="success"
                      value={item.prediction?.draw}
                    />
                    <CProgressBar color="info" value={item.prediction?.lose} />
                  </CProgress>
                </div>
                {isBet ? (
                  <>
                    <div className="row-beted">
                      {item.joined?.depositStatus === 2 ? (
                        <>
                          <img
                            src="./images/bet/check.svg"
                            alt=""
                            className="checker"
                          />
                          {renderText(item.joined?.combatType)}
                        </>
                      ) : (
                        <>
                          <>
                            {/* <img
                              src="./images/bet/checkW.svg"
                              alt=""
                              className="checker"
                            /> */}
                            Your deposit is processing...
                          </>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {item.time === "Ended" || item.time === "Live" ? (
                      <>
                        <div className="row-beted">
                          Make your own prediction next match.
                        </div>
                        <div className="txt-ended-row row-cus">
                          {item.time === "Ended" ? (
                            <>The match has ended.</>
                          ) : (
                            <>The match is live.</>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="guide-finish">
                          <div className="bet-finish">
                            <div className="finish-row">
                              <div className="item">
                                <div className="number-ric">
                                  <NumericInput
                                    strict
                                    className="form-control"
                                    min={1}
                                    max={100000900}
                                    value={quantity}
                                    onChange={(e: any) => {
                                      setQuantity(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="item">
                                <Tooltip title="Each Ticket costs you 1000 1SH . 95% contribute directly into Prize Pool.">
                                  <div className="info-bet">
                                    <img
                                      src="./images/1shoot/info-1s.png"
                                      alt=""
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="item">
                                <div className="button-bet">
                                  {/* classname: btn-dis */}
                                  {account === undefined ? (
                                    <>
                                      <div className="res-btn-connect">
                                        <img
                                          src="./images/1shoot/place.png"
                                          alt=""
                                        />
                                        <ConnectWallet />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {amountBet > 0 && value !== undefined ? (
                                        <>
                                          {!isApproveSwap ? (
                                            <>
                                              {isLoading ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn-dis"
                                                  >
                                                    <img
                                                      src="./images/1shoot/place-dis.png"
                                                      alt=""
                                                    />
                                                    Approve{" "}
                                                    <SyncOutlined spin />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn-bet"
                                                    onClick={() =>
                                                      _approveJoinBet()
                                                    }
                                                  >
                                                    <img
                                                      src="./images/1shoot/place.png"
                                                      alt=""
                                                    />
                                                    Approve
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {isLoading ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn-dis"
                                                  >
                                                    <img
                                                      src="./images/1shoot/place-dis.png"
                                                      alt=""
                                                    />
                                                    Place <SyncOutlined spin />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  {item.time !== "Live" &&
                                                  item.time !== "Ended" ? (
                                                    <>
                                                      <button
                                                        type="button"
                                                        className="btn-bet"
                                                        onClick={() =>
                                                          handleJoinBet()
                                                        }
                                                      >
                                                        <img
                                                          src="./images/1shoot/place.png"
                                                          alt=""
                                                        />
                                                        Place
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="btn-dis"
                                                    >
                                                      <img
                                                        src="./images/1shoot/place-dis.png"
                                                        alt=""
                                                      />
                                                      Place
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="button"
                                            className="btn-dis"
                                          >
                                            <img
                                              src="./images/1shoot/place-dis.png"
                                              alt=""
                                            />
                                            Place
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="time-countdown">
                            Pool closes in &nbsp;
                            <Countdown
                              date={convertDateTime(startUtc)}
                              renderer={rendererCountDown}
                              // onComplete={handleCompleteTime}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {isBet ? (
                  <>
                    {item.joined?.depositStatus === 2
                      ? renderTextBottom(item.joined?.joinedStatus)
                      : ""}
                  </>
                ) : (
                  <>{""}</>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const sliderRef = useRef();
  let paramsBefore: any = {
    ownerAddress: account,
    combatDate: arrayCalendar?.time,
  };
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(arrayCalendarIndex);
    }
  });
  useEffect(() => {
    if (account !== undefined) {
      actions.getWorldcups(paramsBefore);
    }
  }, [account, paramsBefore.combatDate]);

  return (
    <>
      <div className="silder-round-top">
        <Slider
          asNavFor={nav1}
          ref={sliderRef}
          slidesToShow={
            state.listCalendar?.length < 6 ? state.listCalendar?.length : 6
          }
          infinite={true}
          focusOnSelect={true}
          prevArrow={<SampleNextArrow />}
          nextArrow={<SamplePrevArrow />}
          responsive={[
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode: false,
              },
            },
          ]}
        >
          {state.listCalendar &&
            state.listCalendar.map((item: any, i: any) => (
              <ItemTop item={item} />
            ))}
        </Slider>
      </div>
      <div className="silder-round-bot">
        <Slider
          asNavFor={nav2}
          ref={(slider1: any) => setNav1(slider1)}
          slidesToShow={1}
        >
          <div className="item">
            <div className="columns">
              {state.listWorldcups &&
                state.listWorldcups.map((item: any, i: any) => (
                  <ItemBottom item={item} />
                ))}
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
});

const HistotyRow = memo(({ item }: any) => {
  const [state, actions]: any = useHookGameBet();
  const { account } = useActiveWeb3React();
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [isClaim, setIsClaim] = useState(false);

  const withdrawContract = useContract(item.result?.withdrawContract, abiBet);

  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  const handleClaim = async (sign: any, key: any, amount: any) => {
    try {
      setIsLoadingClaim(true);
      await _claim(withdrawContract, sign, key, amount).then((res: any) => {
        if (res.hash) {
          res.wait().then((resWait: any) => {
            if (resWait !== null) {
              let paramsWd = {
                ownerAddress: account,
                txHash: res.hash,
                rowId: key,
              };
              actions.updateWithdraw(paramsWd).then(() => {
                message.success({
                  type: "success",
                  content: "Claim successfully!",
                  className: "custom-class",
                  duration: 2,
                });
                setIsLoadingClaim(false);
                setIsClaim(true);
              });
            }
          });
        } else {
          setIsLoadingClaim(false);
          message.error({
            type: "error",
            content: "Claim Fail !!!",
            className: "custom-class",
            duration: 2,
          });
        }
      });
    } catch (error) {
      message.error({
        type: "error",
        content: "Claim Fail !!!",
        className: "custom-class",
        duration: 2,
      });
      setIsLoadingClaim(false);
    }
  };

  useEffect(() => {
    if (item.result !== null) {
      isClaimed(withdrawContract, account, item.result?.keyId).then((res) => {
        setIsClaim(res);
      });
    }
  }, []);

  const renderStatus = (status: any) => {
    switch (status) {
      case STATUS_HIS.WIN:
        return "statusWin";
        break;
      case STATUS_HIS.LOST:
        return "statusLost";

        break;
      case STATUS_HIS.WAITING:
        return "statusWaiting";

        break;
    }
  };

  return (
    <>
      <tr>
        <td>{item.startTime}</td>
        <td>
          <div className="round-score">
            <div className="his-r">
              <div className="box-img">
                <img className="img-his" src={item.matchs[0]?.logoUrl} alt="" />
              </div>
              <div className="box-img-vs">
                <img src="./images/bet/vs.svg" alt="" />
              </div>
              <div className="box-img">
                <img className="img-his" src={item.matchs[1]?.logoUrl} alt="" />
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="txt">{item.yourChoise}</div>
        </td>
        <td>
          <div className="txt-busd">{item.amount} 1SH</div>
        </td>
        <td>
          <div className={renderStatus(item.status)}>{item.status}</div>
        </td>
        <td>
          {item.result === null ? (
            <>
              <div className="none-claim">---</div>
            </>
          ) : (
            <>
              <div className="button-action">
                <button
                  type="button"
                  className="btn-claim-logs"
                  disabled={isClaim || isLoadingClaim}
                  onClick={() =>
                    handleClaim(
                      item.result?.sign,
                      item.result?.keyId,
                      item.result?.balance
                    )
                  }
                >
                  <img src="./images/1shoot/place.png" alt="" />
                  {isClaim ? (
                    "Claimed"
                  ) : isLoadingClaim ? (
                    <>
                      <Spin indicator={antIcon} />
                    </>
                  ) : (
                    " Claim"
                  )}
                </button>
              </div>
            </>
          )}
        </td>
      </tr>
    </>
  );
});

const HeaderCollapse = (props: any) => {
  const { item } = props;
  return (
    <>
      <div className="top-collapse">
        <div className="row-collapse">
          <div className="item">
            <div className="his-r">
              <div className="box-img">
                <img className="img-his" src={item.matchs[0]?.logoUrl} alt="" />
              </div>
              <div className="box-img-vs">
                <img src="./images/bet/vs.svg" alt="" />
              </div>
              <div className="box-img">
                <img className="img-his" src={item.matchs[1]?.logoUrl} alt="" />
              </div>
            </div>
          </div>
          <div className="item txt-time">{item.startTime}</div>
        </div>
      </div>
    </>
  );
};

const ItemMobile = memo(({ item }: any) => {
  const [state, actions]: any = useHookGameBet();
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [isClaim, setIsClaim] = useState(false);

  const withdrawContract = useContract(item.result?.withdrawContract, abiBet);

  const { account } = useActiveWeb3React();
  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  const handleClaim = async (sign: any, key: any, amount: any) => {
    try {
      setIsLoadingClaim(true);
      await _claim(withdrawContract, sign, key, amount).then((res: any) => {
        if (res.hash) {
          res.wait().then((resWait: any) => {
            if (resWait !== null) {
              let paramsWd = {
                ownerAddress: account,
                txHash: res.hash,
                rowId: key,
              };
              actions.updateWithdraw(paramsWd).then(() => {
                message.success("Claim succeeded!");
                setIsLoadingClaim(false);
                setIsClaim(true);
              });
            }
          });
        } else {
          setIsLoadingClaim(false);
          message.error("Claim Fail!!");
        }
      });
    } catch (error) {
      message.error("Claim Fail!!");
      setIsLoadingClaim(false);
    }
  };

  useEffect(() => {
    if (item.result !== null) {
      isClaimed(withdrawContract, account, item.result?.keyId).then((res) => {
        setIsClaim(res);
      });
    }
  }, []);

  const renderStatus = (status: any) => {
    switch (status) {
      case STATUS_HIS.WIN:
        return "statusWin";
        break;
      case STATUS_HIS.LOST:
        return "statusLost";

        break;
      case STATUS_HIS.WAITING:
        return "statusWaiting";

        break;
    }
  };

  return (
    <>
      <div className="content-mobile-history">
        <div className="item">
          <div className="txt-l">Your choice:</div>
          <div className="txt-r res-1">{item.yourChoise}</div>
        </div>
        <div className="item">
          <div className="txt-l">Amount:</div>
          <div className="txt-r res-2">{item.amount} 1SH</div>
        </div>
        <div className="item">
          <div className="txt-l">Status:</div>
          <div className={renderStatus(item.status)}>{item.status}</div>
        </div>
        <div className="item">
          <div className="txt">
            {item.result === null ? (
              <>{""}</>
            ) : (
              <>
                <div className="button-action">
                  <button
                    type="button"
                    className="btn-claim-logs"
                    disabled={isClaim}
                    onClick={() =>
                      handleClaim(
                        item.result?.sign,
                        item.result?.keyId,
                        item.result?.balance
                      )
                    }
                  >
                    <img src="./images/1shoot/place.png" alt="" />
                    {isClaim ? (
                      "Claimed"
                    ) : isLoadingClaim ? (
                      <>
                        <Spin indicator={antIcon} />
                      </>
                    ) : (
                      " Claim"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

const BetShoot = () => {
  const { account } = useActiveWeb3React();
  const [state, actions]: any = useHookGameBet();
  const [stateMore, actionsMore]: any = useHookGameBetMore();
  const { Panel } = Collapse;
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [crr, setCrr] = useState(0);

  const onChange = (key: any) => {
    console.log(key);
  };

  let paramHis = {
    ownerAddress: account,
    lastTime: 0,
  };

  let paramsWC = {
    ownerAddress: account,
    combatDate: state.listCalendar[crr]?.time,
  };

  useEffect(() => {
    actions.getWorldcups(paramsWC);
  }, [crr]);

  useEffect(() => {
    if (account) {
      actions.getHistory(paramHis);
    }
  }, [account]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    paramHis.lastTime =
      state.listHistory[state.listHistory?.length - 1]?.lastTime;

    actionsMore.getHistoryMore(paramHis).then((res: any) => {
      if (res.length === 0) {
        setEnableLoadMore(false);
        setLoadingMore(false);
      }
    });
  };

  return (
    <>
      <div className="container-foob">
        <div className="content-slick-bet">
          <SliderBet setCrr={setCrr} />
        </div>
      </div>
      <div className="round-bet-logs">
        <div className="container-foob">
          <div className="content-bet-logs">
            <div className="title">
              Prediction History
              <img src="./images/1shoot/vector.png" alt="" />
            </div>
            {account === undefined ? (
              <>
                <div className="round-history-non-account">
                  <div className="wallet">
                    <img src="./images/1shoot/wallet-add.svg" alt="" />
                  </div>
                  <div className="desc">
                    Connect wallet to check out your history
                  </div>
                  <div className="connect-wallet-logs">
                    <div className="res-btn-connect">
                      <img src="./images/1shoot/place.png" alt="" />
                      <ConnectWallet />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isMobile ? (
                  <>
                    {state.listHistory.length === 0 ? (
                      <>
                        <div className="non-data">
                          <img src="./images/bet/non-data.png" alt="" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="main-collapse-history">
                          <Collapse onChange={onChange}>
                            {state.listHistory &&
                              state.listHistory.map((item: any, index: any) => (
                                <Panel
                                  header={<HeaderCollapse item={item} />}
                                  key={index.toString()}
                                >
                                  <ItemMobile
                                    item={item}
                                    account={account}
                                    index={index}
                                  />
                                </Panel>
                              ))}
                            {stateMore.listHistoryMore &&
                              stateMore.listHistoryMore.map(
                                (item: any, index: any) => (
                                  <Panel
                                    header={<HeaderCollapse item={item} />}
                                    key={index.toString()}
                                  >
                                    <ItemMobile
                                      item={item}
                                      account={account}
                                      index={index}
                                    />
                                  </Panel>
                                )
                              )}
                          </Collapse>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="main-table-history">
                      {state.listHistory.length === 0 ? (
                        <div className="non-data-row">
                          <div className="non-data">
                            <img src="./images/1shoot/no-data.png" alt="" />
                          </div>
                        </div>
                      ) : (
                        <>
                          <table>
                            <tr>
                              <th>Time</th>
                              <th>Match</th>
                              <th>Your choice</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                            {state.listHistory &&
                              state.listHistory.map((item: any, i: any) => (
                                <HistotyRow item={item} />
                              ))}
                            {stateMore.listHistoryMore &&
                              stateMore.listHistoryMore.map(
                                (item: any, i: any) => (
                                  <HistotyRow item={item} />
                                )
                              )}
                          </table>
                        </>
                      )}
                      {state.listHistory.length !== 0 && enableLoadMore ? (
                        <>
                          {!loadingMore ? (
                            <>
                              <div className="loadMore-his">
                                <button
                                  className="btn-loadMore-his"
                                  onClick={handleLoadMore}
                                >
                                  <img
                                    src="./images/1shoot/place-dis.png"
                                    alt=""
                                  />
                                  Load more
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="spin-his">
                                <SyncOutlined spin />
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="content-duel-version">
        <div className="container-foob">
          {isMobile ? (
            <>
              <div className="box-img">
                <img src={state.listSetting?.mobiBackground} alt="" />
              </div>
            </>
          ) : (
            <>
              <div className="box-img">
                <img src={state.listSetting?.webBackground} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default BetShoot;
