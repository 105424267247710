import web3 from "web3";
import BigNumber from "bignumber.js";

export const UINT256_MAX =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const _approveBUSD = async (contract, address, amount) => {
  const result = await contract.approve(address, UINT256_MAX);
  return result;
};

export const _getDepositSwap = async (boxContract, amount, keyId) => {
  try {
    const amountTemp = web3.utils.toWei(amount.toString(), "ether");
    return boxContract && boxContract.deposit(amountTemp, keyId);
  } catch (error) {
    // console.log('error>>', error)
    return undefined;
  }
};
export const _claim = async (contract, sign, keyId, amount) => {
  try {
    const amountTemp = convertTokenToWei(amount, 18);
    let args = [amountTemp, keyId, sign];
    const estimatedGas = await contract.estimateGas.claim(...args);
    return contract.claim(...args, {
      gasLimit: estimatedGas,
    });
  } catch (error) {
    console.log("error: ", error);
  }
};

export const isClaimed = async (contract, account, keyId) => {
  return await contract.userClaims(...[account, keyId]);
};

export const _isJoined = async (contract, account, keyId) => {
  return await contract.userPidAmounts(account, keyId);
};

// export const _isJoined = async (contract, account, keyId) => {
//   console.log('contract, account, keyId' , contract, account, keyId);
//   try {
//     return contract.userPidAmounts(account , keyId)
//   } catch (error) {
//     return false
//   }
// }

export const _buyMysteryBox = async (boxContract, campaignId, tokenId) => {
  try {
    return boxContract && boxContract.buyMysteryBox(campaignId, tokenId);
  } catch (error) {
    return undefined;
  }
};

